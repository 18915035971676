import { Injectable, WritableSignal, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidenavService {
  drawerMode = signal<MatDrawerMode>('side');

  sidebarCollapsed: WritableSignal<boolean>;
  sidebarCollapsed$: Observable<boolean>;

  drawerMode$ = toObservable(this.drawerMode);

  sidenavChanged$ = new Subject<void>();

  constructor() {
    const sidebarToggleValue =
      sessionStorage.getItem('sidebar-toggle-value') ?? 'true';
    const value = JSON.parse(sidebarToggleValue);
    this.sidebarCollapsed = signal(value);
    this.sidebarCollapsed$ = toObservable(this.sidebarCollapsed);
  }

  setSidebarCollapsed = (collapsed: boolean) => {
    this.sidebarCollapsed.set(collapsed);
    sessionStorage.setItem(
      'sidebar-toggle-value',
      JSON.stringify(this.sidebarCollapsed())
    );
  };

  changeDrawerMode = (mode: MatDrawerMode) => this.drawerMode.set(mode);

  sidenavChanged = () => this.sidenavChanged$.next();
}
